
import { defineComponent, reactive, ref } from 'vue'
import httpRequest from '@/service'
import BreadCrumb from '@/components/breadCrumb/index.vue'
export default defineComponent({
  name: 'Home',
  components: { BreadCrumb },
  setup() {
    interface ITable {
      id?: string
      favor?: boolean
      [key: string]: any
    }
    const tableData = ref<ITable>([])
    const tableLabel = ref([])
    const total = ref(0)
    const levelTypes = ref<ITable>([])
    const timeTypes = ref<ITable>([]) //时效性数据
    const currentPage = ref(1) //当前页数
    const pageSize = ref(10) //页码大小

    // 表格数据
    const getTableData = (currentPage: number, pageSize: number) => {
      httpRequest
        .post({
          url: 'caseBrowsingHistoryTable',
          params: {
            page: currentPage,
            limit: pageSize
          }
        })
        .then((res: any) => {
          tableData.value = res.data.data1
          total.value = res.data.total

          tableLabel.value = res.data.data2
        })
        .catch((err) => {
          console.log(err)
        })
    }

    // 当前页数
    const handleCurrentChange = (val: number) => {
      currentPage.value = val
      getTableData(currentPage.value, pageSize.value)
    }
    // 改变页码大小
    const handleSizeChange = (val: number) => {
      pageSize.value = val
      getTableData(currentPage.value, pageSize.value)
    }

    getTableData(currentPage.value, pageSize.value)
    return {
      total,
      tableData,
      tableLabel,
      levelTypes,
      timeTypes,
      currentPage,
      pageSize,
      handleCurrentChange,
      handleSizeChange
    }
  }
})
