import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-740a91a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "common-cont" }
const _hoisted_2 = { class: "common-main" }
const _hoisted_3 = { class: "nav" }
const _hoisted_4 = { class: "tables" }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_bread_crumb = _resolveComponent("bread-crumb")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_popover = _resolveComponent("el-popover")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_bread_crumb)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_el_table, {
          data: _ctx.tableData,
          style: {"width":"100%"},
          "max-height": "770",
          height: "770",
          stripe: "",
          ref: "refTable"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tableLabel, (item, index) => {
              return (_openBlock(), _createBlock(_component_el_table_column, {
                key: index,
                prop: item.prop,
                label: item.label,
                height: "70px",
                width: Number(item.width) + 'px'
              }, {
                header: _withCtx(() => [
                  _createElementVNode("div", null, _toDisplayString(item.label), 1 /* TEXT */)
                ]),
                default: _withCtx((scope) => [
                  (item.prop === 'index')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_5, _toDisplayString(scope.$index + 1), 1 /* TEXT */))
                    : (item.prop === 'name')
                      ? (_openBlock(), _createBlock(_component_el_popover, {
                          key: 1,
                          effect: "light",
                          trigger: "hover",
                          placement: "top",
                          width: "auto",
                          "popper-style": "text-align:center"
                        }, {
                          default: _withCtx(() => [
                            _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                          ]),
                          reference: _withCtx(() => [
                            _createElementVNode("div", null, [
                              _createVNode(_component_router_link, {
                                to: {
                          name: 'DetailCasePage',
                          query: { id: scope.row.id }
                        },
                                target: "_blank"
                              }, {
                                default: _withCtx(() => [
                                  _createElementVNode("div", null, _toDisplayString(scope.row.name), 1 /* TEXT */)
                                ]),
                                _: 2 /* DYNAMIC */
                              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["to"])
                            ])
                          ]),
                          _: 2 /* DYNAMIC */
                        }, 1024 /* DYNAMIC_SLOTS */))
                      : (item.prop === 'zfjg')
                        ? (_openBlock(), _createBlock(_component_el_popover, {
                            key: 2,
                            effect: "light",
                            trigger: "hover",
                            placement: "top",
                            width: "auto",
                            "popper-style": "text-align:center"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                            ]),
                            reference: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                        : (_openBlock(), _createBlock(_component_el_popover, {
                            key: 3,
                            effect: "light",
                            trigger: "hover",
                            disabled: true,
                            width: "auto",
                            "popper-style": "text-align:center"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                            ]),
                            reference: _withCtx(() => [
                              _createElementVNode("div", null, _toDisplayString(scope.row[item.prop]), 1 /* TEXT */)
                            ]),
                            _: 2 /* DYNAMIC */
                          }, 1024 /* DYNAMIC_SLOTS */))
                ]),
                _: 2 /* DYNAMIC */
              }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["prop", "label", "width"]))
            }), 128 /* KEYED_FRAGMENT */))
          ]),
          _: 1 /* STABLE */
        }, 8 /* PROPS */, ["data"]),
        _createElementVNode("div", _hoisted_6, [
          _createVNode(_component_el_pagination, {
            total: _ctx.total,
            currentPage: _ctx.currentPage,
            "onUpdate:currentPage": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentPage) = $event)),
            "page-size": _ctx.pageSize,
            "onUpdate:pageSize": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageSize) = $event)),
            "page-sizes": [10, 20, 30, 40, 50],
            background: "",
            layout: "total, sizes,prev, pager, next, jumper",
            "prev-text": "上一页",
            "next-text": "下一页",
            onSizeChange: _ctx.handleSizeChange,
            onCurrentChange: _ctx.handleCurrentChange
          }, null, 8 /* PROPS */, ["total", "currentPage", "page-size", "onSizeChange", "onCurrentChange"])
        ])
      ])
    ])
  ]))
}